import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ArticleOverflowContent from 'components/ArticleOverflowContent';
import CloudVideo from 'components/CloudVideo';
export const _frontmatter = {
  "title": "Using Utility-first CSS to create fluid interfaces",
  "description": "This article shows how a few lines of JavaScript can help build a small, yet solid CSS framework to handle fluid UIs.",
  "date": "2020-05-12T00:00:00.000Z",
  "path": "/articles/using-utility-first-css-to-create-fluid-interfaces/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`It’s been only a few days since I `}<a parentName="p" {...{
        "href": "/articles/react-and-atomic-css/"
      }}>{`last wrote`}</a>{` about the power of Atomic / Utility-first CSS, but I feel like there is plenty left to say about that methodology and how it has become more and more popular over the last years. Frameworks based on it have been widely adopted: Tailwind has `}<a parentName="p" {...{
        "href": "https://github.com/tailwindcss/tailwindcss"
      }}>{`22.2k`}</a>{` stars on GitHub, while Tachyons is close to `}<a parentName="p" {...{
        "href": "https://github.com/tachyons-css/tachyons/"
      }}>{`10k`}</a>{` stars. New technologies have emerged: Atomic CSS-in-JS even seems to be a thing now with libraries such as `}<a parentName="p" {...{
        "href": "https://github.com/styletron/styletron"
      }}>{`Styletron`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/cxs-css/cxs/"
      }}>{`cxs`}</a>{` or `}<a parentName="p" {...{
        "href": "https://github.com/robinweser/fela"
      }}>{`Fela`}</a>{`.`}</p>
    <p>{`However, despite the maturity of the concept, I feel like there are a few blank spaces left around it. One of them is what I call `}<em parentName="p">{`fluid user inferfaces`}</em>{`.`}</p>
    <h2>{`A word on fluid UIs`}</h2>
    <p>{`I first read about that idea a few years ago, in an article called `}<a parentName="p" {...{
        "href": "https://www.smashingmagazine.com/2016/05/fluid-typography/"
      }}>{`Responsive And Fluid Typography With vh And vw Units`}</a>{`, published in `}<a parentName="p" {...{
        "href": "https://www.smashingmagazine.com/"
      }}>{`Smashing Magazine`}</a>{`. As its title implies tough, it was only focused on typography. Here’s the gist of it:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Unlike responsive typography, which changes only at set breakpoints, fluid typography resizes smoothly to match any device width. It is an intuitive option for a web in which we have a practically infinite number of screen sizes to support.`}</p>
    </blockquote>
    <p>{`A fluid interface basically follows that exact same principle, but extends it to other CSS properties which values are most likely to change with screen size: `}<code parentName="p" {...{
        "className": "text"
      }}>{`margin`}</code>{`, `}<code parentName="p" {...{
        "className": "text"
      }}>{`padding`}</code>{`, `}<code parentName="p" {...{
        "className": "text"
      }}>{`width`}</code>{`, `}<code parentName="p" {...{
        "className": "text"
      }}>{`height`}</code>{` etc.`}</p>
    <p>{`That kind of approach ensures that CSS values won’t “jump” when reaching a breakpoint, thus offering a better rendering on different viewports:`}</p>
    <ArticleOverflowContent className="d-grid sm:g-2" mdxType="ArticleOverflowContent">
  <div>
    <figure>
      <CloudVideo file="responsive-resize_vtfzl3" mdxType="CloudVideo" />
      <figcaption>Responsive interface</figcaption>
    </figure>
  </div>
  <div>
    <figure>
      <CloudVideo file="fluid-resize_a3kcpv" mdxType="CloudVideo" />
      <figcaption>Fluid interface</figcaption>
    </figure>
  </div>
    </ArticleOverflowContent>
    <p>{`In the side-by-side comparison above, we clearly see the benefits of a fluid UI: font-sizes and spacings are dynamically adjusted, content won’t suddenly become bigger when reaching a new breakpoint.`}</p>
    <h2>{`How does it work?`}</h2>
    <p>{`At its core, a fluid CSS property is based on a mathematic formula powered by the `}<code parentName="p" {...{
        "className": "text"
      }}>{`calc()`}</code>{` CSS function and the `}<code parentName="p" {...{
        "className": "text"
      }}>{`vw`}</code>{` unit:`}</p>
    <figure>{`
  `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1a0a19a9e9fca890bbad61f294bff326/29d74/advanced-calc-800-opt.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.24271844660194%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAABAklEQVQY0z2PS2uDQBSF/e/WTQPmHxiLxkc3LjQiPgKJewmIrS2hKMEKbpT6mhlnnE5bmrM4XA738V2OUrquK3PLsgRBeNxsRFHs+57+yrbtB0EQt1ue5z3Puzf/iVuWZZ5nhFBVVa9Z9pKmH9frPAwLhACAsizf8zy7XN7y/PN2IxDCaVowJoSwLdwwDHVdN03Tdh2eZ4rQihAFAAMwjuMXQyCEdt0KIe46Ok2obcn/ce7OgDE+nc9+EERR5DhOGIasiOM4SRLP96PjMQhD+3BgBctd1y2K4meYMTBn5M+mud/vNU2TdztVVRVFMU2Tvfoky7qus0SWZeaGYUiSlKbpN1Yn/OUvk5QfAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Fluid CSS calculation",
            "title": "Fluid CSS calculation",
            "src": "/static/1a0a19a9e9fca890bbad61f294bff326/29d74/advanced-calc-800-opt.png",
            "srcSet": ["/static/1a0a19a9e9fca890bbad61f294bff326/2b93d/advanced-calc-800-opt.png 206w", "/static/1a0a19a9e9fca890bbad61f294bff326/799e5/advanced-calc-800-opt.png 412w", "/static/1a0a19a9e9fca890bbad61f294bff326/29d74/advanced-calc-800-opt.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Illustration © Smashing Magazine`}</figcaption>
    </figure>
    <p>{`Four values need to be defined in order to make it work: minimum/maximum CSS values, and minimum/maximum breakpoints. With that formula, we tell the browser that we want our text to have a 16 pixels `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-size`}</code>{` at `}<code parentName="p" {...{
        "className": "text"
      }}>{`400px`}</code>{`, 24 pixels at `}<code parentName="p" {...{
        "className": "text"
      }}>{`800px`}</code>{`, and that it needs to adjust fluidly in-between. Here, we are using `}<code parentName="p" {...{
        "className": "text"
      }}>{`px`}</code>{` values, but the formula will work equally fine with other values, such as `}<code parentName="p" {...{
        "className": "text"
      }}>{`em`}</code>{` or `}<code parentName="p" {...{
        "className": "text"
      }}>{`rem`}</code>{`.`}</p>
    <p>{`However, if we simply apply that formula without adding any breakpoint, we will face an issue. Our values will keep decreasing on smaller resolutions, and increasing on higher resolutions:`}</p>
    <ArticleOverflowContent mdxType="ArticleOverflowContent">
  <figure>
    <CloudVideo file="fluid-resize-no-breakpoints_fvhjxj" mdxType="CloudVideo" />
    <figcaption>Fluid interface with no breakpoints</figcaption>
  </figure>
    </ArticleOverflowContent>
    <p>{`In order to avoid that, we will have to update our code to add fixed minimum and maximum values. Our CSS class will look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`/* The font-size will stay at 16px under 400px */`}</span>{`
`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.some-class`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 16px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* The font-size will be fluid between 400 and 800px */`}</span>{`
`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` screen `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`and`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 400px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.some-class`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`calc`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`16px + `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`24 - 16`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` * `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`100vw - 400px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` / `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`800 - 400`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* The font-size will become fix again above 800px */`}</span>{`
`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` screen `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`and`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 800px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.some-class`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 24px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Writing each fluid CSS class by hand seems a bit cumbersome though, let’s see how we can use a sprinkle of JavaScript to automate the job for us.`}</p>
    <h2>{`From CSS to JS`}</h2>
    <p>{`The easiest way to generate the CSS above is to use a JavaScript `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals"
      }}>{`template litteral`}</a>{`. I have created a function called `}<code parentName="p" {...{
        "className": "text"
      }}>{`fluidCSS`}</code>{`, which takes an object as parameter and spits out the result. Simple, yet enough for what we are looking to achieve.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` fluidCSS `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  prefix `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  selector `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  properties `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  values `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  breakpoints `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`prefix`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`selector`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{` {
  `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`properties`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`map`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`property`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token template-string"
              }}><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span>{`property`}<span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`: `}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span>{`values`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`.`}</span>{`min`}<span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`px;`}</span><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`join`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`''`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`
}
@media screen and (min-width: `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`breakpoints`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`min`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`px) {
  `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`prefix`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`selector`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{` {
    `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`properties
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`map`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`
        `}<span parentName="span" {...{
                "className": "token parameter"
              }}>{`property`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{`
          `}<span parentName="span" {...{
                "className": "token template-string"
              }}><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span>{`property`}<span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`: calc(`}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span>{`values`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`.`}</span>{`min`}<span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`px + (`}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span>{`values`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`.`}</span>{`max`}<span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{` - `}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span>{`values`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`.`}</span>{`min`}<span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`) * (100vw - `}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span>{`breakpoints`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`.`}</span>{`min`}<span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`px) / (`}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span>{`breakpoints`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`.`}</span>{`max`}<span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{` - `}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span>{`breakpoints`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`.`}</span>{`min`}<span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`));`}</span><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span></span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`join`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`''`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`
  }
}
@media screen and (min-width: `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`breakpoints`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`max`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`px) {
  `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`prefix`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`selector`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{` {
    `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`properties`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`map`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`property`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token template-string"
              }}><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span>{`property`}<span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`: `}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span>{`values`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`.`}</span>{`max`}<span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`px;`}</span><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`join`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`''`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`
  }
}
`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The function is then called like this (`}<code parentName="p" {...{
        "className": "text"
      }}>{`prefix`}</code>{` is not needed here as it defaults to an empty string):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`fluidCSS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`selector`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'.some-class'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`properties`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'font-size'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`values`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`24`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`breakpoints`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`400`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`800`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`OK, we have a working helper! Let’s move to the next step…`}</p>
    <h2>{`Building the framework`}</h2>
    <p>{`This website is powered by the same CSS framework as the one used on `}<a parentName="p" {...{
        "href": "https://www.algolia.com"
      }}>{`www.algolia.com`}</a>{`, but modifying it to generate fluid class names would have taken a while. I really wanted to stick to the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/KISS_principle"
      }}><em parentName="a">{`Keep It Simple Stupid`}</em>{` (KISS)`}</a>{` principle and iterate quickly to have a working proof of concept.`}</p>
    <p>{`If we want to easily create a small, standalone Utility-first framework, three things are needed:`}</p>
    <ul>
      <li parentName="ul">{`The `}<code parentName="li" {...{
          "className": "text"
        }}>{`fluidCSS`}</code>{` helper function detailed above`}</li>
      <li parentName="ul">{`A configuration array holding all possible values`}</li>
      <li parentName="ul">{`A small Node script to write the actual CSS file`}</li>
    </ul>
    <p>{`Now that I have detailed how `}<code parentName="p" {...{
        "className": "text"
      }}>{`fluidCSS`}</code>{` works, it must be paired with the configuration array. Here is an excerpt of the one I created (full source `}<a parentName="p" {...{
        "href": "https://github.com/ronanlevesque/ronanlevesque/blob/master/fluid-css.config.js"
      }}>{`here`}</a>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` selectors `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`className`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fsz'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`properties`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'font-size'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`minMax`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`32`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`className`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'ml'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`properties`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'margin-left'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`minMax`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`className`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mt'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`properties`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'margin-top'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`minMax`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`48`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`96`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`className`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mr'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`properties`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'margin-right'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`minMax`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// etc.`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`I went with shortcut names for my classes here, but it’s really only a matter of personal preference. I also like to use the same prefix naming convention as Tailwind, like so: `}<code parentName="p" {...{
        "className": "text"
      }}>{`.prefix:color-red`}</code>{`. In the end, our class names should look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`fluid:className-min-max`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`To achieve that result, we need to map over our `}<code parentName="p" {...{
        "className": "text"
      }}>{`selectors`}</code>{` array and return one big string using the `}<code parentName="p" {...{
        "className": "text"
      }}>{`join()`}</code>{` method:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` styles `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` selectors
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`selector`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
    selector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`minMax
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`value`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fluidCSS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`prefix`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'.fluid\\\\:'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`selector`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`selector`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`className`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`value`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token number"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`value`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token number"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`properties`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` selector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`properties`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`values`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`breakpoints`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`500`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1200`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`join`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`join`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The next step is to write that content into a new CSS file using the `}<code parentName="p" {...{
        "className": "text"
      }}>{`fs`}</code>{` Node module.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "js"
      }}><code parentName="pre" {...{
          "className": "js"
        }}>{`fs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`writeFile`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'src/css/fluid.css'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` styles`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`err`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
  err
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`err`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'✅ Fluid CSS classes successfully generated.'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`And that’s it, we now have a fully-working Utility-first fluid framework under the shape of a simple `}<code parentName="p" {...{
        "className": "text"
      }}>{`.css`}</code>{` `}<a parentName="p" {...{
        "href": "https://github.com/ronanlevesque/ronanlevesque/blob/master/src/css/fluid.css"
      }}>{`file`}</a>{`. We can simply import it where we want, as we would with any other CSS file.`}</p>
    <h2>{`What about CSS-in-JS?`}</h2>
    <p>{`While our solution should cover most situations, how would one implement fluid CSS within tools such as `}<a parentName="p" {...{
        "href": "https://emotion.sh/docs/introduction"
      }}>{`Emotion`}</a>{`? Well, quite easily actually! Instead of relying on a CSS file, we can implement the idea directly at the component level thanks to the `}<code parentName="p" {...{
        "className": "text"
      }}>{`fluidCSS`}</code>{` function and the `}<code parentName="p" {...{
        "className": "text"
      }}>{`&`}</code>{` alias:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "jsx"
      }}><code parentName="pre" {...{
          "className": "jsx"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Using Emotion with \`css\``}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div
    className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`css`}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`
      `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`fluidCSS`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`selector`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'&'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
        `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`properties`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'font-size'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
        `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`values`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
          `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`min`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`16`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
          `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`max`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`24`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
        `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
        `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`breakpoints`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
          `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`min`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`400`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
          `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`max`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`800`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
        `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`
      padding: 32px;
      border: 1px solid red;
    `}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    Some content
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Using Emotion with \`styled\``}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` Component `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` styled`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`div`}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`
  `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`fluidCSS`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
    `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`selector`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'&'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
    `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`properties`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'font-size'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
    `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`values`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
      `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`min`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`16`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
      `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`max`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`24`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
    `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
    `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`breakpoints`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
      `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`min`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`400`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
      `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`max`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`800`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
    `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`
  padding: 32px;
  border: 1px solid red;
`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Component`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Some content`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Component`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Of course, doing that for every property seems a bit cumbersome: it will be easier to create a new function based on `}<code parentName="p" {...{
        "className": "text"
      }}>{`fluidCSS`}</code>{`, with some properties already pre-filled.`}</p>
    <h2>{`The future of fluid CSS`}</h2>
    <p>{`During my research to write this blog post, I stumbled upon an interesting article from `}<a parentName="p" {...{
        "href": "https://css-tricks.com/"
      }}>{`CSS Tricks`}</a>{`, called `}<a parentName="p" {...{
        "href": "https://css-tricks.com/simplified-fluid-typography/"
      }}>{`Simplified Fluid Typography`}</a>{`. In it, Chris explains how the new `}<code parentName="p" {...{
        "className": "text"
      }}>{`min()`}</code>{` and `}<code parentName="p" {...{
        "className": "text"
      }}>{`max()`}</code>{` functions could be used to simplify the CSS code that creates our fluid values, like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`html`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`min`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`max`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`16px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 4vw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 24px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`He goes even further with the `}<code parentName="p" {...{
        "className": "text"
      }}>{`clamp()`}</code>{` function, which has the same effect with less code!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`html`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`16px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 4vw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 24px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Nifty, right? Well, not so fast… For now, the browser support for those functions is `}<a parentName="p" {...{
        "href": "https://caniuse.com/#feat=css-math-functions"
      }}>{`pretty low`}</a>{`, but it’s an interesting idea to keep in mind for when they are more widely supported.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      